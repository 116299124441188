import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { URL, ImgURL } from "../url/api";
import ActivityIndicator from "../component/ActivityIndicator";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import almond from "../asset/pngImage/almond.png";
import Logo from "../asset/pngImage/logo.png";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { refreshCart } from "../reduxSetup/slices/productsSlice";
function OrderPlaced() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentPageUrl = window.location.href;
  const [newData, setNewData] = useState("");
  const [loading, setLoading] = useState(false);
  const [Token, setToken] = useState("");
  useEffect(() => {
    checkLogin();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        setToken(JSON.parse(session));
        getCartData(JSON.parse(session));
        getOrderDetails(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const showToast = (title) => {
    toast.success(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const showToastErr = (title) => {
    toast.error(title, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const getCartData = (session) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${session}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getCart`, requestOptions)
      .then((response) => {
        if (response.status === 401) {
          Cookies.remove("user_session");
          Cookies.remove("user_address");
        } else if (response.ok) {
          return response.text();
        } else {
          throw new Error("Network response was not ok");
        }
      })
      .then((result) => {
        const newData = JSON.parse(result);
        newData.data.cart.map((item) => {
          dispatch(refreshCart(item));
        });
        if (newData?.data?.cart?.length === "0") {
          navigate("/", { replace: true });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getOrderDetails = (session) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${session}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getOrderDetails/${id} `, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result).data;
        setNewData(newData);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const cancelOrder = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/cancelOrder/${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.error === false) {
          showToast(newData.msg);
          getOrderDetails(Token);
        } else {
          showToastErr(newData.msg);
        }
      })
      .catch((error) => console.log("cancelOrder error", error));
  };

  const handleImageError = (event) => {
    event.target.src = almond; // Use the logo variable from your imports
  };
  const date_time = (timestamp) => {
    return moment(timestamp).format("DD/MM/YYYY hh:mm A");
  };
  const getPaymentGatewayKey = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getPaymentGatewayKey`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        handlePayment(newData.data);
      })
      .catch((error) => console.log("error", error));
  };
  const handlePayment = async (razorPay_id) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK faild to load. Are you online ?");
      return;
    }
    var options = {
      key: razorPay_id, // Enter the Key ID generated from the Dashboardcurrency: "INR",
      name: "Zaya Dry Fruits", //your business name
      image: Logo,
      order_id: newData?.pg_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
        name: newData?.contact_person, //your customer's name
        email: "gaurav.kumar@example.com",
        // contact: newData.contact_no, //Provide the customer's phone number for better conversion rates
      },
      theme: {
        color: "#3399cc",
      },
      handler: function (response) {
        checkPayment();
      },
      modal: {
        ondismiss: function () {
          checkPayment();
        },
      },
    };
    const paymentInstance = new window.Razorpay(options);
    paymentInstance.open();
  };
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const checkPayment = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${URL}/checkPayment/${newData?.id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        if (newData.error === false) {
          showToast(newData.msg);
          getOrderDetails(Token);
        } else {
          showToastErr(newData.msg);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };
  if (loading) {
    return <ActivityIndicator />;
  }
  return (
    <div className="topMargin container-fluid">
      <Helmet>
        <title>Order Placed</title>
        {/* <meta name="Description" content={data?.description} /> */}
        <meta name="KeyWords" content="DryFruits, Nuts, Pulses" />
        <meta property="og:title" content={"Order Placed"} />
        {/* <meta property="og:description" content={data?.description} /> */}
        <meta property="og:url" content={currentPageUrl} />
        <meta property="og:site_name" content="Zaya Dryfruits" />
        {/* <meta property="og:image" content={`${ImgURL}${data.img}`} /> */}
      </Helmet>
      <div className="row">
        {newData?.pay_mode === "online" ? (
          newData?.status !== "cancelled" ? (
            newData?.is_paid === 0 ? (
              <div className="alert alert-danger col-md-12">
                <p style={{ textAlign: "center" }}>
                  Your Payment is not completed.
                </p>
              </div>
            ) : (
              <div className="alert alert-success col-md-12">
                <p style={{ textAlign: "center" }}>
                  Your Payment is completed.
                </p>
              </div>
            )
          ) : null
        ) : null}

        <div className="col-md-8 my-2">
          {newData?.items?.map((item) => {
            return (
              <div
                className="card mb-2"
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div className="col-md-4 p-1">
                  <img
                    key={item.id}
                    alt={item?.name}
                    src={`${ImgURL}${item.img}`}
                    loading="lazy"
                    onError={handleImageError}
                    className="card-img-top mx-auto"
                    style={{
                      height: "80px",
                      resizeMode: "cover",
                      width: "80px",
                    }}
                  />
                </div>
                <div className="col-md-8 p-1">
                  <Link
                    className="my-nav-link"
                    to={`/product/${item.product_id}/${item?.name}`}
                  >
                    <p
                      className="pTag"
                      style={{ fontSize: 15, fontWeight: 500 }}
                    >
                      {item.name} ({item.variant.size})
                    </p>
                    {item.mrp !== item.sp ? (
                      <p
                        className="pTag"
                        style={{ fontSize: 15, fontWeight: 500 }}
                      >
                        <del>₹{item.mrp}</del>₹{item.sp}
                      </p>
                    ) : (
                      <p
                        className="pTag"
                        style={{ fontSize: 15, fontWeight: 500 }}
                      >
                        ₹{item.sp}
                      </p>
                    )}
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-md-4 my-2">
          <div className="card p-2">
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <h5 className="my-2">#id: {newData.id}</h5>
              {newData?.status === "cancelled" ? null : (
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#CancelOrder"
                  style={{ marginTop: 10 }}
                >
                  Cancel Order
                </button>
              )}
            </div>
            <h6 className="my-2">Address Details</h6>
            <p className="pTag">
              <i className="fa-solid fa-user mx-2"></i>
              {newData.contact_person}
            </p>
            <p className="pTag">
              <i className="fa-solid fa-phone mx-2"></i>
              {newData.contact_no}
            </p>
            {newData.house_no &&
              newData.locality &&
              newData?.city &&
              newData.pincode &&
              newData.state && (
                <p className="pTag">
                  <i class="fa-solid fa-location-dot mx-2"></i>
                  {newData.house_no},{newData.locality}, {newData.city},
                  {newData.state}, {newData.pincode}
                </p>
              )}
            <hr />
            <div
              className="horizontalContainer"
              style={{ justifyContent: "space-between" }}
            >
              <p>Total</p>
              <p>₹ {newData.sub_total}</p>
            </div>
            <div
              className="horizontalContainer"
              style={{ justifyContent: "space-between" }}
            >
              <p>Total Saving</p>
              <p>₹ {newData.total_savings}</p>
            </div>
            <div
              className="horizontalContainer"
              style={{ justifyContent: "space-between" }}
            >
              <p>Delivery charges</p>
              <p>₹ {newData.delivery_charges}</p>
            </div>
            <div
              className="horizontalContainer"
              style={{ justifyContent: "space-between" }}
            >
              <p>Order amount</p>
              <p>₹ {newData.order_total}</p>
            </div>
            <hr />
            <div
              className="horizontalContainer"
              style={{ justifyContent: "space-between" }}
            >
              <p>Payable amount</p>
              <p>₹ {newData.payable_amt}</p>
            </div>
            <div
              className="horizontalContainer"
              style={{ justifyContent: "space-between" }}
            >
              <p> Pay mode: </p>
              <p>{newData.pay_mode}</p>
            </div>
            {newData.coupon == null ? null : (
              <div
                className="horizontalContainer"
                style={{ justifyContent: "space-between" }}
              >
                <p>Coupon Code </p>
                <p>{newData.coupon}</p>
              </div>
            )}
            <div
              className="horizontalContainer"
              style={{ justifyContent: "space-between" }}
            >
              <p> Delivery Date: </p>
              <p>{date_time(newData.delivery_date)}</p>
            </div>
            <div
              className="horizontalContainer"
              style={{ justifyContent: "space-between" }}
            >
              <p> Delivery status: </p>
              {/* <p>{newData.status}</p> */}
              {newData.status === "cancelled" ? (
                <p className="text-danger">{newData.status}</p>
              ) : newData.status === "delivered" ? (
                <p className="text-success">{newData.status}</p>
              ) : (
                <p className="text-warning">{newData.status}</p>
              )}
            </div>
            {newData.is_paid == "0" &&
            newData.pay_mode === "online" &&
            newData.status === "pending" ? (
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginTop: 10 }}
                onClick={() => {
                  getPaymentGatewayKey();
                }}
              >
                Try Again
              </button>
            ) : null}
            <hr />
            {newData.order_notes && (
              <div>
                <p>{newData.order_notes}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="CancelOrder"
        tabIndex="-1"
        aria-labelledby="CancelOrderLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="CancelOrderLabel">
                Cancel Order
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {" "}
              Are you sure ? You want Cancel Order !
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  cancelOrder(newData.id);
                }}
                data-bs-dismiss="modal"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderPlaced;
