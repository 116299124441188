import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { URL } from "../url/api";
import { Helmet } from "react-helmet";
import moment from "moment";

const Order = () => {
  const [orderList, setOrderList] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    checkLogin();
  }, []);
  const checkLogin = async () => {
    try {
      const session = await Cookies.get("user_session");
      if (session !== undefined && session !== null) {
        const token = JSON.parse(session);
        getOrders(token);
      } else {
        console.log("isLoggedIn::::::", session);
      }
    } catch (error) {
      // There was an error on the native side
    }
  };
  const getOrders = (token) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${URL}/getOrders`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const newData = JSON.parse(result);
        setOrderList(newData.data);
      })
      .catch((error) => console.log("error", error));
  };
  const handleNavigateToAboutPage = (itemId) => {
    navigate(`/OrderPlaced/${itemId?.id}`);
  };
  const date_time = (timestamp) => {
    return moment(timestamp).format("DD/MM/YYYY hh:mm A");
  };
  return (
    <div className="container">
      <Helmet>
        <title>My Order</title>
      </Helmet>
      {orderList &&
        orderList.map((item) => {
          return (
            <div
              className="card my-2 p-2"
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                padding: 2,
              }}
            >
              <div>
                <p className="pTag">#id: {item.id}</p>
                <p className="pTag">₹{item.payable_amt}</p>
              </div>
              <div>
                <p className="pTag">
                  Delivery Date: {date_time(item.delivery_date)}
                </p>
                {item.status === "cancelled" ? (
                  <p className="text-danger">{item.status}</p>
                ) : item.status === "delivered" ? (
                  <p className="text-success">{item.status}</p>
                ) : (
                  <p className="text-warning">{item.status}</p>
                )}
                {/* <p className="pTag">{item.status}</p> */}
              </div>
              <div
                style={{
                  marginTop: 4,
                  marginBottom: 2,
                  marginRight: 4,
                }}
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleNavigateToAboutPage(item)}
                >
                  <span>View Details</span>
                </button>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Order;
