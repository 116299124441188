import "./App.css"; // App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MouseParticles from "react-mouse-particles";
import Header from "./component/Header";
import Footer from "./component/Footer";
import route from "./Routes/route";
// import { Sitemap } from "react-router-sitemap";

function App() {
  return (
    <Router>
      <Header
        title={"Home"}
        Shop={"Our Stores"}
        DryFruits={"Dry Fruits"}
        Nuts={"Nuts"}
        Seeds={"Seeds"}
        Masale={"Masale"}
        All={"Wholesale Price"}
      />
      <MouseParticles
        g={1}
        color="random"
        cull=".h-100.card"
        level={6}
      />
      <Routes>
        {route.map((route, index) => (
          <Route key={index} path={route.path} element={<route.component />} />
        ))}
      </Routes>
      <Footer className="fixed-footer" />
      {/* Generate Sitemap */}
      {/* <Sitemap routes={route} /> */}

      {/* {new Sitemap(router).build("https://zayadryfruits.com/").save("./sitemap.xml")} */}
    </Router>
  );
}

export default App;
